import styled from 'styled-components';

export const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: none;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    position: relative;
    > div {
      color: #343434;
      font-weight: 600;
      transition: color 0.5s;
    }
    &:hover {
      > div {
        color: #2d5dad;
      }
    }
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
				margin-right: 25px;

				&:last-child {
						margin-right: unset;
				}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;
