import React from 'react';
import { useInView } from 'react-intersection-observer';
import './animations.css';

const ScrollRevealAnimation = ({
  animation,
  duration,
  delay,
  children,
  offset,
  animateOnce = true,
  className,
  id,
  style,
}) => {
  const [ref, inView] = useInView({
    rootMargin: `${offset} 0px`, // negative percentage/px y-value through element before firing
    triggerOnce: animateOnce,
  });
  return (
    <>
      {animation && (
        <div
          className={`${className || ''}  ${inView ? 'animated' : ''} ${inView ? animation : ''}`}
          id={id}
          style={{
            animationDuration: `${duration}s`,
            animationDelay: inView ? `${delay}s` : 0,
            animationFillMode: `both`,
            opacity: inView ? 1 : 0,
            ...style,
          }}
          ref={ref}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default ScrollRevealAnimation;
