import React from 'react';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import social from './social.json';

import './Footer.scss';

export const Footer = () => (
  <>
    <div className="footer-container-text" id="contact">
      <ScrollRevealAnimation animation="subtleFadeInDown" delay={0.5} offset="0px">
        <div className="work-me">¿Interesado en trabajar conmigo?</div>
        <div className="work-me-description">
          ¿Tienes un proyecto y quieres mi ayuda? <br />
          Envíame un mensaje a <a href="mailto:nanasalazarmusic@gmail.com">nanasalazarmusic@gmail.com</a>.
        </div>
      </ScrollRevealAnimation>
    </div>

    <div id="footer">
      <div className="footer-container">
        <ScrollRevealAnimation
          style={{
            width: '80%',
            maxWidth: '400px',
            margin: '0 auto 50px',
          }}
          animation="zoomIn"
          delay={0.5}
          offset="0px"
          duration={1}
        >
          <img
            src={require('../../../assets/logo.png')}
            alt="Nana Salazar"
            style={{
              width: '100%',
            }}
          />
        </ScrollRevealAnimation>
        <div className="info">
          <div className="details">
            <span>
              © All rights are reserved | {new Date().getFullYear()} | Made with by{' '}
              <a href="https://mc2io.info/" rel="noopener noreferrer" target="_blank">
                Marión Carámbula.
              </a>
            </span>
          </div>
          <div className="links">
            {social.map(({ id, name, link, icon }) => (
              <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
                <img width="24" src={icon} alt={name} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
);
