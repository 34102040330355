module.exports = {
  defaultTitle: 'Nana Salazar',
  author: 'Nana Salazar',
  url: 'https://nanasalazar.com/',
  legalName: 'Nana Salazar',
  defaultDescription: 'Soy Nana Salazar, Ingeniera de Sonido.',
  socialLinks: {
    instagram: 'https://www.instagram.com/nanasalazarmusic/',
    youtube: 'https://www.youtube.com/user/nanasalazardiaz/videos',
  },
  googleAnalyticsID: 'G-BW9LFF2GJK',
};
