import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  padding: 1rem 2.5rem;
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  user-select: none;
  color: #fff;
  background: #374686;
  border-radius: 40px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }
`;
