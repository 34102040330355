import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import NavbarLinks from '../NavbarLinks';
import './NavBar.scss';

const Navbar = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <navbar className="navbar">
      <div className="wrapper-nav">
        <ScrollRevealAnimation className="logo" animation="fadeIn" delay={0} offset="20px">
          <Link to="/" theme={theme}>
            <img
              src={require('../../../../assets/logo-simplificado.png')}
              style={{ width: '110px', margin: '0' }}
              alt="Nana Salazar"
            />
          </Link>
        </ScrollRevealAnimation>
        <NavbarLinks desktop />
      </div>
    </navbar>
  );
};

export default Navbar;
