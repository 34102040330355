import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ScrollRevealAnimation from 'components/ScrollRevealAnimation/ScrollRevealAnimation';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => (
  <Wrapper desktop={desktop}>
    <AnchorLink href="#about">
      <ScrollRevealAnimation animation="fadeIn" delay={0.25} offset="20px">
        Acerca de mí
      </ScrollRevealAnimation>
    </AnchorLink>

    <AnchorLink href="#projects">
      <ScrollRevealAnimation animation="fadeIn" delay={0.5} offset="20px">
        Portafolio
      </ScrollRevealAnimation>
    </AnchorLink>
    <AnchorLink href="#contact">
      <ScrollRevealAnimation animation="fadeIn" delay={0.75} offset="20px">
        Trabaja conmigo
      </ScrollRevealAnimation>
    </AnchorLink>
  </Wrapper>
);

export default NavbarLinks;
